import spotify from '../content/thumbnails/spotify.png'
import inspigo from '../content/thumbnails/inspigo.png'
import twitter from '../content/thumbnails/twitter.png'

export default [
  {
    title: '#BlokirKominfo Sesi 2',
    path: 'https://twitter.com/i/spaces/1djGXPNPykvGZ?s=20',
    img: twitter,
  },
  {
    title: '#BlokirKominfo',
    path: 'https://twitter.com/i/spaces/1MYxNngLPQQxw?s=20',
    img: twitter,
  },
  {
    title: 'Menjaga Data Digital Pribadi',
    path: 'https://sc6d7.app.goo.gl/zhGT',
    img: inspigo,
  },
  {
    title: 'Data 90 Juta Pelanggan E- Commerce Pada Bocor, Kok Bisa? ft. Teguh Aprianto (Asumsi Bersuara)',
    path: 'https://open.spotify.com/episode/3yiDDC1WGvJ07puKteA98P',
    img: spotify,
  },
  {
    title: 'Ngobrol Santai Bareng @8ballrap',
    path: 'https://open.spotify.com/episode/41tspUQquEdLxBvppiN0Gg?si=2Q6M6nEqSNSW_2qCzIUHSg',
    img: spotify,
  },
  {
    title: 'Bug Bounty Hunter & UU ITE',
    path: 'https://open.spotify.com/episode/3kiOe2lcayeHz9s31pwDrW?si=V3T_gpw0Qb2OVLb0-S70xQ',
    img: spotify,
  },
  {
    title: 'All About Bug Bounty',
    path: 'https://open.spotify.com/episode/6PolfSlYS5hEXL6QMH5jbA?si=lKWXBVwiTQiCLpjDZX9KWQ',
    img: spotify,
  },
]
