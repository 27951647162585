import youtube from '../content/thumbnails/youtube.png'
import instagram from '../content/thumbnails/instagram.png'

export default [
  {
    title: 'CNN Indonesia: Pemerintah Ancam Blokir Platform Digital',
    path: 'https://www.youtube.com/watch?v=OS8XCNGdFjA',
    img: youtube,
  },
  {
    title: 'Kick Andy: Gara-Gara Game',
    path: 'https://www.youtube.com/watch?v=UaoMY3I3fdI&t=2849s',
    img: youtube,
  },
  {
    title: 'Narasi Newsroom: Spyware Pegasus Buatan Israel Instai Aktivis dan Jurnalis Seluruh Dunia',
    path: 'https://www.instagram.com/tv/CRkwOqzBjNy/',
    img: instagram,
  },
  {
    title: 'Narasi Newsroom: Dahsyatnya Efek Hoaks',
    path: 'https://www.instagram.com/p/CRY2l_lDiQk/',
    img: instagram,
  },
  {
    title: '15 Minutes Metro TV: Jaga Passwordmu',
    path: 'https://www.youtube.com/watch?v=kJ_2POM5_lE',
    img: youtube,
  },
  {
    title: 'Narasi TV: Bagaimana AS Intai Jutaan Orang di Dunia Lewat Aplikasi Salat dan Lainnya?',
    path: 'https://youtu.be/VFAW8ykC3qI',
    img: youtube,
  },
  {
    title: 'Narasi TV: Yakin Email Kamu Masih Aman?',
    path: 'https://www.instagram.com/p/CGoYQ2_h3l0/',
    img: instagram,
  },
  {
    title: 'Narasi TV: Bagaimana Cara Memproteksi Diri dari Peretasan Data Pribadi?',
    path: 'https://www.youtube.com/watch?v=86zvpJDtgGY',
    img: youtube,
  },
  {
    title: 'Mengenal Fungsi Untuk Setiap Tools di Burp Suite',
    path: 'https://www.youtube.com/watch?v=yHrrOPd7lJI',
    img: youtube,
  },
  {
    title: 'Intercept iOS (iPhone & iPad) Traffic with Burp Suite',
    path: 'https://www.youtube.com/watch?v=n14tBv9_jwA',
    img: youtube,
  },
  {
    title: 'PHPUnit Unauthenticated Remote Code Execution',
    path: 'https://www.youtube.com/watch?v=VVaEpoaiiwM',
    img: youtube,
  },
  {
    title: 'Remote Code Execution via HTTP Post',
    path: 'https://www.youtube.com/watch?v=abVt8IXIJd8',
    img: youtube,
  },
]
