import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../layout'
import PostListing from '../components/PostListing'
import SimpleListing from '../components/SimpleListing'
import SEO from '../components/SEO'
import speaking from '../../data/speaking'
import podcasts from '../../data/podcasts'
import videos from '../../data/videos'

export default class Index extends Component {
  render() {
    const { data } = this.props

    const latestPostEdges = data.latest.edges
    const popularPostEdges = data.popular.edges

    return (
      <Layout>
        <Helmet title={`Catatan Harian Teguh Aprianto`} />
        <SEO />
        <div className="container">
          <div className="lead">
            <div className="elevator">
              <h1>{`Hai, Saya Teguh Aprianto 👋`}</h1>
              <p>
                Situs ini berisi informasi tentang saya, tulisan, opini dan kebanyakan adalah dokumentasi untuk saya pribadi ketika sedang belajar atau membagikan sesuatu. Untuk mendapatkan informasi yang sifatnya cepat, silakan ikuti saya di Twitter <a href="https://twitter.com/secgron" target="_blank" >@secgron</a>. Jika ingin menghubungi saya, silakan menuju ke halaman <Link to="/contact">kontak</Link>.
              </p>
              <Link to="/about" className="selengkapnya">
                Selengkapnya tentang Teguh Aprianto
              </Link>
            </div>
          </div>
        </div>

        <div className="container front-page">
          <section className="section">
            <h2>
              Tulisan Terbaru
              <Link to="/blog" className="view-all">
                Lihat Semuanya
              </Link>
            </h2>
            <PostListing simple postEdges={latestPostEdges} />
          </section>

          <section className="section">
            <h2>Video</h2>
            <SimpleListing simple data={videos} />
          </section>

          <section className="section">
            <h2>Podcast</h2>
            <SimpleListing simple data={podcasts} />
          </section>

          <section className="section">
            <h2>Liputan, Wawancara & Publikasi</h2>
            <SimpleListing simple data={speaking} />
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 7
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 7
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`
