import jpnn from '../content/thumbnails/jpnn.png'
import jawapos from '../content/thumbnails/jawapos.png'
import distruptor from '../content/thumbnails/distruptor.png'
import ct from '../content/thumbnails/ct.png'
import magdalene from '../content/thumbnails/magdalene.png'
import kumparan from '../content/thumbnails/kumparan.png'
import times from '../content/images/timesindonesia.png'
import remotivi from '../content/images/remotivi.png'
import tirto from '../content/images/tirto.png'
import krasia from '../content/images/krasia.png'
import linetoday from '../content/images/linetoday.png'
import yahoo from '../content/images/yahoo.png'
import tempo from '../content/images/tempobig.png'
import cnn from '../content/images/cnn.png'
import techinasia from '../content/images/techinasia.png'
import vice from '../content/images/vice.png'
import restofworld from '../content/images/restofworld.jpeg'
import mojok from '../content/images/mojok.png'
import bbc from '../content/images/bbc.png'
import jakpost from '../content/images/jp.jpg'
import liputan6 from '../content/images/liputan6.jpg'
import kontan from '../content/images/kontan.png'
import detik from '../content/images/detik.jpg'
import asumsi from '../content/images/asumsi.png'
import inews from '../content/images/inews.png'
import jenius from '../content/images/jenius.png'
import cnbc from '../content/images/cnbc.png'
import dw from '../content/thumbnails/dw.jpg'

export default [
  {
    title: 'Data PeduliLindungi Tak Dienkripsi? Pakar Sindir Beda Ucap dan Fakta',
    path: 'https://www.cnnindonesia.com/teknologi/20221116133010-192-874507/data-pedulilindungi-tak-dienkripsi-pakar-sindir-beda-ucap-dan-fakta',
    img: cnn,
  },
  {
    title: 'Deret Kelemahan Teknologi Face Recognition yang Dipakai Nilang',
    path: 'https://www.cnnindonesia.com/teknologi/20221110133701-185-872025/deret-kelemahan-teknologi-face-recognition-yang-dipakai-nilang',
    img: cnn,
  },
  {
    title: 'Netizen Ragu Pemuda Madiun-Cirebon Adalah Bjorka: Awas Salah Tangkap',
    path: 'https://www.cnnindonesia.com/teknologi/20220915120831-192-848190/netizen-ragu-pemuda-madiun-cirebon-adalah-bjorka-awas-salah-tangkap',
    img: cnn,
  },
  {
    title: 'Praktisi Keamanan Siber Bicara Data SIM Card Bocor Diduga dari Kominfo',
    path: 'https://tekno.tempo.co/read/1629670/praktisi-keamanan-siber-bicara-data-sim-card-bocor-diduga-dari-kominfo',
    img: tempo,
  },
  {
    title: 'Data Pelanggan Diduga Bocor, Warganet Pertanyakan Kemampuan Indihome',
    path: 'https://www.cnnindonesia.com/teknologi/20220822071700-192-837327/data-pelanggan-diduga-bocor-warganet-pertanyakan-kemampuan-indihome',
    img: cnn,
  },
  {
    title: 'Kominfo Blokir WA, FB, dan Google Bila Tak Daftar PSE',
    path: 'https://www.dw.com/id/kominfo-blokir-wa-fb-dan-google-bila-tak-daftar-pse/a-62505074',
    img: dw,
  },
  {
    title: 'Pengamat Kritik Situs PSE Kominfo Berantakan',
    path: 'https://inet.detik.com/law-and-policy/d-6198406/pengamat-kritik-situs-pse-kominfo-berantakan',
    img: detik,
  },
  {
    title: 'Aktivis Pengkritik PSE Kominfo Kena Doxing dan Diserbu Spam Chat WA',
    path: 'https://www.cnnindonesia.com/teknologi/20220720163254-185-823962/aktivis-pengkritik-pse-kominfo-kena-doxing-dan-diserbu-spam-chat-wa',
    img: cnn,
  },
  {
    title: 'Rilis First Look, Film Mencuri Raden Saleh Tayang 25 Agustus 2022 di Bioskop',
    path: 'https://kumparan.com/kumparanhits/rilis-first-look-film-mencuri-raden-saleh-tayang-25-agustus-2022-di-bioskop-1yBEhyqNkbO',
    img: kumparan,
  },
  {
    title: 'Program Konsultasi Otonomi Daerah di Metaverse Dikritik Cuma Taktik Pengadaan Barang',
    path: 'https://www.vice.com/id/article/n7npkx/program-kovi-otda-metaverse-kemendagri-dikritik-pakar-keamanan-siber-pemborosan-anggaran',
    img: vice,
  },
  {
    title: 'Ramai-ramai Kritik Face Recognition Polisi di Kasus Ade Armando',
    path: 'https://www.cnnindonesia.com/teknologi/20220415135506-185-785202/ramai-ramai-kritik-face-recognition-polisi-di-kasus-ade-armando',
    img: cnn,
  },
  {
    title: 'Banyak di SPBU, Ini Tanda ATM yang Dipasang Alat Skimming',
    path: 'https://www.cnbcindonesia.com/tech/20220508095727-37-337180/banyak-di-spbu-ini-tanda-atm-yang-dipasang-alat-skimming',
    img: cnbc,
  },
  {
    title: 'Cara Kerja Skimming Sedot Data Kartu ATM Sampai Kuras Rekening',
    path: 'https://www.cnnindonesia.com/teknologi/20220328185955-185-777162/cara-kerja-skimming-sedot-data-kartu-atm-sampai-kuras-rekening',
    img: cnn,
  },
  {
    title: 'Dampak Kebocoran Data Bisa Menimpa Kita Semua',
    path: 'https://www.jenius.com/highlight/detail/dampak-kebocoran-data-bisa-menimpa-kita-semua?utm_medium=socialorganic&utm_source=guestwriter&utm_term=220104&utm_campaign=gwdampakkebocorandatabisamenimpakitasemua_220104',
    img: jenius,
  },
  {
    title: 'Ahli: Kertas Saja Dicurangi, Apalagi E-Voting Pemilu 2024 Usul Kominfo',
    path: 'https://www.cnnindonesia.com/teknologi/20220323164541-192-775230/ahli-kertas-saja-dicurangi-apalagi-e-voting-pemilu-2024-usul-kominfo',
    img: cnn,
  },
  {
    title: 'Memahami Cara Kerja Pelaku Penipuan dan Apa yang Bisa Kamu Lakukan',
    path: 'https://www.cocreate.id/articles/memahami-cara-kerja-pelaku-penipuan-dan-apa-yang-bisa-kamu-lakukan/?utm_source=organic&utm_medium=social+media',
    img: jenius,
  },
  {
    title: 'Banyak Kasus Data Bocor, Ahli Pertanyakan Hasil Investigasi',
    path: 'https://www.cnnindonesia.com/teknologi/20211116145202-185-721900/banyak-kasus-data-bocor-ahli-pertanyakan-hasil-investigasi',
    img: cnn,
  },
  {
    title: 'Kejahatan Siber dengan Modus Rekayasa Sosial Meningkat Selama Pandemi',
    path: 'https://www.inews.id/techno/internet/kejahatan-siber-dengan-modus-rekayasa-sosial-meningkat-selama-pandemi',
    img: inews,
  },
  {
    title: 'PeduliLindungi "Setor Data Pengguna" ke Server Analitik Telkom?',
    path: 'https://asumsi.co/post/7054/peduli-lindungi-setor-data-pengguna-ke-server-analitik-telkom',
    img: asumsi,
  },
  {
    title: 'PeduliLindungi Setor Data ke Server Analitik Telkom?',
    path: 'https://inet.detik.com/security/d-5741855/pedulilindungi-setor-data-ke-server-analitik-telkom',
    img: detik,
  },
  {
    title: 'Kenali bentuk kejahatan siber yang mengintai ketika transaksi mobile banking melesat',
    path: 'https://keuangan.kontan.co.id/news/kenali-bentuk-kejahatan-siber-yang-mengintai-ketika-transaksi-mobile-banking-melesat',
    img: kontan,
  },
  {
    title: 'Nasabah Bank Digital Harus Waspada Kejahatan Siber Bermodus Social Engineering',
    path: 'https://www.liputan6.com/tekno/read/4697049/nasabah-bank-digital-harus-waspada-kejahatan-siber-bermodus-social-engineering',
    img: liputan6,
  },
  {
    title: 'Indonesians data just sitting there, hackers say',
    path: 'https://tirto.id/menilik-keamanan-potensi-penyalahgunaan-data-di-pedulilindungi-gjXy',
    img: jakpost,
  },
  {
    title: 'Menilik Keamanan & Potensi Penyalahgunaan Data di PeduliLindungi',
    path: 'https://tirto.id/menilik-keamanan-potensi-penyalahgunaan-data-di-pedulilindungi-gjXy',
    img: tirto,
  },
  {
    title: 'Selama Hidup di Indonesia Masih Harus Fotokopi e-KTP, Teruslah Marah',
    path: 'https://mojok.co/tap/esai/selama-hidup-di-indonesia-masih-harus-fotokopi-e-ktp-teruslah-marah/',
    img: mojok,
  },
  {
    title: 'Data eHAC bocor, pakar siber sebut "Infrastruktur keamanan digital pemerintah Indonesia sangat buruk"',
    path: 'https://www.bbc.com/indonesia/indonesia-58406164',
    img: bbc,
  },
  {
    title: 'Pegasus, Mata-Mata Asal Israel',
    path: 'https://kumparan.com/kumparan-plus/pegasus-mata-mata-asal-israel-1wH3YzlfFH2',
    img: kumparan,
  },
  {
    title: 'Kebocoran Data Pribadi, BPJS Kesehatan Bakal Digugat',
    path: 'https://www.cnnindonesia.com/teknologi/20210606200515-185-650991/kebocoran-data-pribadi-bpjs-kesehatan-bakal-digugat',
    img: cnn,
  },
  {
    title: 'Lagi-Lagi Bocor: Betapa Lemahnya Perlindungan Data Pribadi Warga',
    path: 'https://tirto.id/lagi-lagi-bocor-betapa-lemahnya-perlindungan-data-pribadi-warga-ggcD',
    img: tirto,
  },
  {
    title: 'BPJS Kesehatan: Data ratusan juta peserta diduga bocor - Otomatis yang dirugikan masyarakat, kata pakar',
    path: 'https://www.bbc.com/indonesia/indonesia-57196905',
    img: bbc,
  },
  {
    title: 'Teguh Aprianto, Hacker yang Bongkar Lemahnya Situs Pemerintah',
    path: 'https://www.cnnindonesia.com/teknologi/20210506205940-185-639732/teguh-aprianto-hacker-yang-bongkar-lemahnya-situs-pemerintah',
    img: cnn,
  },
  {
    title: 'Hanya Budiman Sudjatmiko yang Bisa Wujudkan Perlindungan Data Pribadi di Indonesia',
    path: 'https://mojok.co/tap/esai/hanya-budiman-sudjatmiko-yang-bisa-wujudkan-perlindungan-data-pribadi-di-indonesia/',
    img: mojok,
  },
  {
    title: 'Indonesia’s ethical hackers take on perceptions and the government',
    path: 'https://restofworld.org/2021/indonesias-white-hats-unite/',
    img: restofworld,
  },
  {
    title: 'Pejabat Sering Promosi Big Data dan 4.0, Teguh Aprianto Yakin Mereka Tak Paham Maknanya',
    path: 'https://www.vice.com/id/article/v7maym/salah-kaprah-istilah-big-data-dan-revolusi-40-di-indonesi-versi-pejabat?utm_source=viceidtw',
    img: vice,
  },
  {
    title: 'Bismillah dan Rahasia Jadi Password Populer Pengguna Internet di Indonesia',
    path: 'https://www.vice.com/id/article/88amka/bismillah-dan-rahasia-dua-password-paling-pasaran-pengguna-internet-di-indonesia',
    img: vice,
  },
  {
    title: 'Is biometric identification enough to stop fraud in Indonesia?',
    path: 'https://kr-asia.com/is-biometric-identification-enough-to-stop-fraud-in-indonesia',
    img: krasia,
  },
  {
    title: 'Situs Cek Data Pribadi yang Bocor',
    path: 'https://www.cnnindonesia.com/teknologi/20200925074930-185-550741/situs-cek-data-pribadi-yang-bocor',
    img: cnn,
  },
  {
    title: 'Cybersecurity expert exposes illegal data harvesting of state-owned ISP IndieHome',
    path: 'https://kr-asia.com/cybersecurity-expert-exposes-illegal-data-harvesting-of-state-owned-isp-indiehome',
    img: krasia,
  },
  {
    title: 'Dibongkar Teguh Aprianto, Telkom Curi Data Browsing Pelanggan Indihome untuk Bisnis Metranet',
    path: 'https://cyberthreat.id/read/8495/Dibongkar-Teguh-Ethical-Hacker-Telkom-Curi-Data-Browsing-Pelanggan-Indihome-untuk-Bisnis-Metranet',
    img: ct,
  },
  {
    title: 'Grab, Gojek used to intimidate activists in Indonesia',
    path: 'https://www.techinasia.com/warning-grab-gojek-utilized-intimidate-activists-indonesia',
    img: techinasia,
  },
  {
    title: 'Indonesia sees mysterious, escalating cyber attacks in 2020',
    path: 'https://kr-asia.com/indonesian-sees-mysterious-escalating-cyber-attacks-in-2020',
    img: krasia,
  },
  {
    title: 'Tokopedia rolls out fintech lending service amid data breach investigation',
    path: 'https://www.techinasia.com/tokopedia-rolls-fintech-lending-service-data-breach-investigation',
    img: techinasia,
  },
  {
    title: 'KreditPlus Akui Ada Kebocoran Data Pengguna, Segera Lapor BSSN',
    path: 'https://kumparan.com/kumparantech/kreditplus-akui-ada-kebocoran-data-pengguna-segera-lapor-bssn-1twOvjzayVX/full',
    img: kumparan,
  },
  {
    title: 'OJK Konfirmasi Kreditplus Soal Dugaan Kebocoran Data Nasabah',
    path: 'https://www.cnnindonesia.com/ekonomi/20200804131347-78-531949/ojk-konfirmasi-kreditplus-soal-dugaan-kebocoran-data-nasabah',
    img: cnn,
  },
  {
    title: 'Pinjaman Online KreditPlus Kebocoran 896 Ribu Data Pengguna',
    path: 'https://today.line.me/id/article/Pinjaman+Online+KreditPlus+Kebocoran+896+Ribu+Data+Pengguna-a83rDG',
    img: linetoday,
  },
  {
    title: 'Beberkan Kebocoran Data, Teguh Aprianto Mengaku Disambangi Polisi',
    path: 'https://nasional.tempo.co/read/1355282/beberkan-kebocoran-data-teguh-aprianto-mengaku-disambangi-polisi',
    img: tempo,
  },
  {
    title: 'Bahaya Di Balik Peretasan Pangkalan Data Anggota Polri',
    path: 'https://tirto.id/bahaya-di-balik-peretasan-pangkalan-data-anggota-polri-fJzU',
    img: tirto,
  },
  {
    title: 'Diperiksa Polisi, Pencuit Server Polri Dibobol Hacker Pertanyakan Informasinya Disebut Hoaks',
    path: 'https://id.berita.yahoo.com/diperiksa-polisi-pencuit-server-polri-021610298.html',
    img: yahoo,
  },
  {
    title: 'Keganjilan Dalam Pembobolan WhatsApp dan Penangkapan Ravio Patra',
    path: 'https://tirto.id/keganjilan-dalam-pembobolan-whatsapp-dan-penangkapan-ravio-patra-flkF',
    img: tirto,
  },
  {
    title: 'What can we learn from Tokopedia alleged 91-million data leak?',
    path: 'https://kr-asia.com/what-can-we-learn-from-tokopedias-alleged-91-million-data-leak',
    img: krasia,
  },
  {
    title: 'Bagaimana Cara Akun WhatsApp Ravio Patra Dibajak?',
    path: 'https://remotivi.or.id/amatan/589/bagaimana-cara-akun-whatsapp-ravio-patra-dibajak',
    img: remotivi,
  },
  {
    title: 'Ethical Hacker Indonesia Luncurkan Situs Informasi Seputar Virus Corona di Indonesia',
    path: 'https://www.timesindonesia.co.id/read/news/254812/ethical-hacker-indonesialuncurkan-situs-informasi-seputar-virus-corona-di-indonesia',
    img: times,
  },
  {
    title: '6 Cara Lepaskan Diri Dari Jerat Mantan yang Kuasai Akun Media Sosial',
    path: 'https://magdalene.co/story/cara-lepas-dari-mantan',
    img: magdalene,
  },
  {
    title: 'Tips Mengamankan Akun Media Sosial',
    path: 'https://magdalene.co/story/tips-mengamankan-akun-media-sosial',
    img: magdalene,
  },
  {
    title: 'What Technology Will Most Impact the Future of Cybersecurity? 33 Experts Share Their Insights',
    path: 'https://www.disruptordaily.com/cybersecurity-technology-trends/',
    img: distruptor,
  },
  {
    title: 'KADIN: Sektor Industri Relatif Aman dari Hacker',
    path: 'https://cyberthreat.id/read/278/KADIN-Sektor-Industri-Relatif-Aman-dari-Hacker',
    img: ct,
  },
  {
    title: 'Ethical Hacker Indonesia Dampingi Penerobos Website KPU',
    path: 'https://www.jawapos.com/nasional/hukum-kriminal/24/04/2019/ethical-hacker-indonesia-dampingi-penerobos-website-kpu/',
    img: jawapos,
  },
  {
    title: 'Pemilik Toko Online Perlu Waspadai Formjacking',
    path: 'https://cyberthreat.id/read/279/Pemilik-Toko-Online-Perlu-Waspadai-Formjacking',
    img: ct,
  },
  {
    title: 'Pembelaan Ethical Hacker Indonesia untuk Penguji Situs KPU',
    path: 'https://www.jpnn.com/news/pembelaan-ethical-hacker-indonesia-untuk-penguji-situs-kpu',
    img: jpnn,
  },
  {
    title: 'Belajar dari Kasus Istri Artis Andrey Taulany',
    path:
      'https://cyberthreat.id/read/301/Belajar-dari-Kasus-Istri-Artis-Andrey-Taulany',
    img: ct,
  },
]
